const { app } = require("./auth")

const put = async (collection, data) => {
    try {
        const db = app.firestore()
        const doc = db.collection(collection).doc(data?.id)
        const ref = await doc.get()
        if (ref.exists) {
            await doc.set(data, { merge: true })
            return true
        } else {
            await doc.set(data)
            return true
        }
    } catch (error) {
        console.log(error)
        return false
    }
}

module.exports = put