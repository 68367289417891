// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { onTokenChanged } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import storage module
import "firebase/compat/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCtLnNe8E0Y44FNxnO4Z4dHWR19nLFeVAM",
  authDomain: "form-d82c5.firebaseapp.com",
  projectId: "form-d82c5",
  storageBucket: "form-d82c5.appspot.com",
  messagingSenderId: "264496421042",
  appId: "1:264496421042:web:4311aa9954ebba028711ab",
  measurementId: "G-4Y4ZGDP8RL"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app); // Get reference to Firebase Storage

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfR-iwpAAAAAHtEEHsvHJS_SYBJ9QI9751x33do"),
  isTokenAutoRefreshEnabled: true,
});

// Listen for token changes
onTokenChanged(appCheck, async (token) => {
  return token;
});

// Export necessary modules
export { app, analytics, auth, firestore, storage }; // Export storage module