import React from 'react'

const NumberInput = ({ title, content, placeholder, onChange }) => {
    return (
        <React.Fragment>
            <div className="input-react-container-sa inter-font">
                <div className="-div-content-title-">
                    <div className="con-d-input-a-title">
                        <div className="in-title-s-c container-title">
                            {title}
                        </div>
                    </div>
                    <div className="s-container-input-d">
                        <div className="-input-content-">
                            <input className='input-s-w' onChange={(e) => onChange(e.target.value)} type="number" placeholder={placeholder} value={content} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NumberInput