import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
    <React.StrictMode>
        <Router>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </Router>
    </React.StrictMode>
);
