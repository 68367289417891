import React from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./containers/Form";
import { HelmetProvider } from "react-helmet-async";
import "./style/main.css"
import "./style/responsive.css"

const App = () => {
    return (
        <React.Fragment>
            <HelmetProvider>
                <header className="app_header">
                </header>
                <Routes>
                    <Route path="/" element={<Form />} />
                </Routes>
            </HelmetProvider>
        </React.Fragment>
    );
}

export default App