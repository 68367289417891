import { useState, useEffect } from 'react';
import put from '../database/put';

const useForm = (data) => {
    const [result, setResult] = useState(false)
    const [error, setError] = useState(null)

    const handleError = (e) => {
        setError(e)
    }

    useEffect(() => {
        const set = async () => {
            try {
                if (data.id) {
                    const updated = await put("form", data)
                    setResult(updated)
                }
            } catch (error) {
                handleError(error)
                setResult(false)
            }
        }

        set()
    }, [data])

    return { result, error }

}

export default useForm