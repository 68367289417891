const { ethers } = require("ethers")
const abi = require("../toppdogs/abi.json")
const api = require("../constants/api")
const address = require("../toppdogs/address")

async function getBalance(account) {
    try {
        const provider = new ethers.JsonRpcProvider(api)
        const contract = new ethers.Contract(address, abi, provider)
        const haveToken = await contract.balanceOf(account)
        return haveToken
    } catch (error) {
        console.log(error)
        return false
    }
}

module.exports = getBalance