const ethereum = require("../constants/ethereum")

async function sign(msg, address) {
    const signature = await ethereum.request({
        method: 'personal_sign',
        params: [msg, address]
    })

    return signature
}

module.exports = sign