import React from 'react'

const Header = ({ title, info, image }) => {
    return (
        <React.Fragment>
            <div className="content-top-box">
                <div className="container-header-top">
                    <div className="form-header-content-top inter-font">
                        <div className="content-info-image">
                            <div className="image-content-box">
                                <img src={image} alt="image" className="img-header-box" />
                            </div>
                        </div>
                        <div className="title-container-header">
                            <div className="header-title">
                                {title}
                            </div>
                        </div>
                        <div className="content-info-header-top">
                            <div className="header-info-top">
                                {info}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header