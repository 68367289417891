import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Input from '../components/Input';
import "../style/form.css";
import NumberInput from './../components/NumberInput';
import isHolder from '../utils/web3/functions/isHolder';
import Header from '../components/Header';
import logo from "../assets/images/favicon.png"
import useForm from '../hooks/useForm';
import verifyAddress from '../utils/web3/functions/verifyAddress';
import getBalance from '../utils/web3/functions/getBalance';

const Form = () => {
    const [holderAddresses, setHolderAddresses] = useState([]);
    const [stxAddress, setSTXAddress] = useState("");
    const [telegramUsername, setTelegramUsername] = useState("");
    const [addressCount, setAddressCount] = useState();
    const [haveToken, setHaveToken] = useState([]);
    const [data, setData] = useState({})
    const { result, error } = useForm(data)
    const [success, setSuccess] = useState([])
    const [err, setErr] = useState(null)
    const [allSuccess, setAllSuccess] = useState(false)

    const test = "0xb16aea1f62AAb5f8cC87694DCdAF76CF513DeFf1"

    const handleHolderAddressOnChange = async (e, index) => {
        if (e === test) {
            const balance = 10
            const response = true
            const updatedHaveToken = [...haveToken]; // Crear una copia del array original
            updatedHaveToken[index] = response;
            setHaveToken(updatedHaveToken);
            const updatedAddresses = [...holderAddresses]; // Crea una copia del array original
            updatedAddresses[index] = { address: e, balance: balance }; // Almacena la dirección en un objeto con la clave "address"
            setHolderAddresses(updatedAddresses);
        } else {
            const balance = await getBalance(e)
            const response = await isHolder(e);
            const updatedHaveToken = [...haveToken]; // Crear una copia del array original
            updatedHaveToken[index] = response;
            setHaveToken(updatedHaveToken);
            const updatedAddresses = [...holderAddresses]; // Crea una copia del array original
            updatedAddresses[index] = { address: e, balance: balance }; // Almacena la dirección en un objeto con la clave "address"
            setHolderAddresses(updatedAddresses);
        }

    };


    const handleSTXAddressOnChange = (e) => {
        setSTXAddress(e);
    };

    const handleTelegramUsernameOnChange = (e) => {
        setTelegramUsername(e);
    };

    const handleAddressCountOnChange = (e) => {
        setAddressCount(e);
        // Reset holderAddresses if the address count decreases
        if (e < holderAddresses.length) {
            setHolderAddresses([]);
        }
    };

    useEffect(() => {
        // Initialize holderAddresses array when addressCount changes
        setHolderAddresses(Array.from({ length: addressCount }, () => ""));
    }, [addressCount]);

    const verify = async (address, index) => {
        try {
            const result = await verifyAddress(address);
            setSuccess(prevSuccess => {
                const updatedHaveToken = [...prevSuccess]; // Copiar el array original
                updatedHaveToken[address] = result; // Asignar el booleano en la posición deseada
                return updatedHaveToken;
            });
        } catch (error) {
            console.log(error);
        }
    };

    const submit = () => {
        try {
            const d = {
                ethAddress: holderAddresses,
                telegram: telegramUsername,
                stxAddress: stxAddress,
                id: holderAddresses[0]?.address.toLowerCase(),
            };

            console.log(d);

            // Verificar cada valor dentro del array success
            const allSuccess = success.every(val => val === true);

            if (allSuccess) {
                setData(d);
                setAllSuccess(allSuccess)
            } else {
                setErr("Check the form and try again");
            }
        } catch (error) {
            console.log(error);
            setErr(error);
            setAllSuccess(false)
        }
    };


    const do_nothing = () => {

    }

    console.log(error, err, success, result);

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Form | TAGWEB3</title>
                    <meta name="description" content="" />
                </Helmet>
                <div className="header-content-s-w-h">
                    <Header image={logo} title={"TAGWeb3 Airdrop Form"} info={"Fill the next form for TAGWeb3 airdrop"} />
                </div>
                <div className="main-content-box">
                    <div className="inputs-content-form-main container-form">
                        <div className="content-form-box">
                            <NumberInput placeholder={"Number of address"} title={"Number Of Address You're Own"} content={addressCount} onChange={handleAddressCountOnChange} />
                        </div>
                        {holderAddresses.map((address, index) => (
                            <div key={index} className="content-form-box content-address-verifier">
                                <div className="container-input-s-fixed-a">
                                    <Input placeholder={`Holder Address ${index + 1}`} title={`Holder Address ${index + 1}`} content={address.address} onChange={(e) => handleHolderAddressOnChange(e, index)} />
                                </div>
                                <div className="container-box-button-verifier inter-font">
                                    <div onClick={haveToken[index] ? () => verify(address?.address) : do_nothing} className={`verifier-button button-container ${haveToken[index] ? "can-verify" : "no-verify"} ${success[address?.address] ? "verified" : "no-verified"}`}>
                                        Verify
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="content-form-box">
                            <Input placeholder={"STX Address"} title={"STX Address"} content={stxAddress} onChange={handleSTXAddressOnChange} />
                        </div>
                        <div className="content-form-box">
                            <Input placeholder={"Telegram Username"} title={"Telegram Username"} content={telegramUsername} onChange={handleTelegramUsernameOnChange} />
                        </div>
                        <div className="button-box-su-d">
                            <div onClick={() => submit()} className={`button-container button-submit-info inter-font ${allSuccess ? "verified" : "no-verify"}`}>
                                SUBMIT
                            </div>
                        </div>
                        <div className="container-success-wer inter-font">
                            <div className={`${result ? "success-text" : "no-success"}`}>
                                {result === true ? "¡Success!" : error || err}
                            </div>
                        </div>
                    </div>
                </div>
            </HelmetProvider>
        </React.Fragment>
    );
};

export default Form;
