const ethereum = require("../constants/ethereum")

async function connect() {
    try {
        const accounts = await ethereum.request({
            method: 'eth_requestAccounts'
        })

        const account = accounts[0]
        return account
    } catch (error) {
        console.log(error)
    }
}

module.exports = connect