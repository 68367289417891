const { ethers } = require("ethers")
const ethereum = require("../constants/ethereum")
const connect = require("./walletConnect")
const sign = require("./sign")
const { Buffer } = require('buffer')

async function verifyAddress(address) {
    try {
        const account = await connect()
        const msg = `Airdrop verification - TAGWeb3\n\naddress: ${account}`
        const toSign = `${Buffer.from(msg, 'utf8').toString('hex')}`
        const signature = await sign(toSign, account)
        const signer = ethers.verifyMessage(msg, signature)
        console.log(signer, account)
        return signer.toLowerCase() === account.toLowerCase()  && account.toLowerCase()  === address.toLowerCase() 
    } catch (error) {
        console.log(error)
        return false
    }
}

module.exports = verifyAddress
